import React from 'react';
import './Hamburger.css';

const Hamburger = ({ mobileOpen, onClick }) => {
  return (
    <div
      className={
        'screen-small-hide Hamburger-div' +
        (mobileOpen ? ' Hamburger-open' : ' Hamburger-closed')
      }
      onClick={onClick}
    >
      <div className="Hamburger-bar Hamburger-b1"></div>
      <div className="Hamburger-bar Hamburger-b2"></div>
      <div className="Hamburger-bar Hamburger-b3"></div>
    </div>
  );
};

export default Hamburger;
