import React from 'react';
import './ImgNav.css';

const ImgNav = ({
  activeSubObj = {},
  activeImage,
  onClick,
  subSiteOpacity,
  textColor,
}) => (
  <div
    className={
      'ImgNav-container hide' +
      (activeSubObj.type !== 'images'
        ? ''
        : ' screen-small-flex-row align-center justify-center')
    }
    style={{ opacity: subSiteOpacity }}
  >
    {activeSubObj.images &&
      activeSubObj.images.length > 1 &&
      activeSubObj.images.map((image, index) => (
        <button
          key={activeSubObj.title + '-button-' + image}
          className="ImgNav-button"
          onClick={() => onClick(index)}
          style={
            index === activeImage
              ? { backgroundColor: textColor, borderColor: textColor }
              : { backgroundColor: 'transparent', borderColor: textColor }
          }
        ></button>
      ))}
  </div>
);

export default ImgNav;
