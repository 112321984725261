import React from 'react';
import './Content.css';

// components
import SubImages from './SubImages';
import SubVideo from './SubVideo';
import SubAbout from './SubAbout';

const ChooseContent = (activeImage, activeSubObj, textColor) => {
  switch (activeSubObj.type) {
    case 'images':
      return (
        <SubImages
          images={activeSubObj.images}
          activeImage={activeImage}
          title={activeSubObj.title}
          textColor={textColor}
        />
      );
    case 'video':
      return <SubVideo activeSubObj={activeSubObj} textColor={textColor} />;
    case 'about':
      return <SubAbout activeSubObj={activeSubObj} textColor={textColor} />;
    default:
      return null;
  }
};

const Content = ({
  activeSubObj = {},
  activeImage,
  subSiteOpacity,
  textColor,
}) => (
  <div
    className={'Content-container Content-' + activeSubObj.type}
    style={{ opacity: subSiteOpacity }}
  >
    {ChooseContent(activeImage, activeSubObj, textColor)}
  </div>
);

export default Content;
