import React, { useState, useEffect } from 'react';

// CSS
import './css/App.css';
import './css/global.css';
import './css/utilities.css';

// Components
import SiteTitle from './components/SiteTitle';
import Projects from './components/Projects';
import Content from './components/Content';
import Hamburger from './components/Hamburger';
import ImgNav from './components/ImgNav';
import TextRenderer from './components/TextRenderer';
import TitleString from './components/TitleString';

// functions
import getTextColor from './functions/getTextColor';
import getTitleString from './functions/getTitleString';

function App() {
  const [siteData, setSiteData] = useState({});
  const [mobileOpen, toggleMobile] = useState(false);
  const [activeProject, setActiveProject] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const [subSiteOpacity, setSubSiteOpacity] = useState(1);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/data.json')
      .then((response) => response.json())
      .then((data) => {
        setSiteData(data);
      });
  }, []);

  return (
    <div className="App-container flex-column min-height screen-small-p-left-large screen-small-p-right-large screen-small-p-top-large screen-small-p-bottom-large">
      <div className="maxed flex-column p screen-small-no-p screen-small-flex-row screen-small-justify-between screen-small-m-bottom">
        <div className="maxed flex-row justify-between m-bottom screen-small-no-m">
          <SiteTitle
            title={siteData.title}
            textColor={
              siteData.projects !== undefined
                ? getTextColor(siteData.projects[activeProject], activeImage)
                : 'black'
            }
          />
          <Hamburger
            onClick={() => toggleMobile((e) => !e)}
            mobileOpen={mobileOpen}
          />
        </div>
        <TitleString
          textColor={
            siteData.projects !== undefined
              ? getTextColor(siteData.projects[activeProject], activeImage)
              : 'black'
          }
          titleString={
            siteData.projects !== undefined
              ? getTitleString(siteData.projects[activeProject], activeImage)
              : 'Title Missing'
          }
        />
      </div>
      <Projects
        projects={siteData.projects}
        mobileOpen={mobileOpen}
        onClick={(i) => {
          toggleMobile(false);
          setSubSiteOpacity(0);
          setTimeout(() => {
            setActiveProject(i);
            setActiveImage(0);
            setSubSiteOpacity(1);
          }, 500);
        }}
        activeProject={activeProject}
        textColor={
          siteData.projects !== undefined
            ? getTextColor(siteData.projects[activeProject], activeImage)
            : 'black'
        }
      />
      <div
        className={
          siteData.projects &&
          siteData.projects[activeProject].type !== 'images'
            ? 'hide'
            : 'screen-small-flex-column screen-small-justify-end screen-small-align-end screen-small-grow'
        }
      >
        <TextRenderer
          text={
            siteData.projects
              ? siteData.projects[activeProject].description
              : ''
          }
          links={
            siteData.projects ? siteData.projects[activeProject].links : []
          }
          opacity={subSiteOpacity}
          textColor={
            siteData.projects !== undefined
              ? getTextColor(siteData.projects[activeProject], activeImage)
              : 'black'
          }
        />
      </div>
      <Content
        activeSubObj={
          siteData.projects ? siteData.projects[activeProject] : undefined
        }
        activeImage={activeImage}
        subSiteOpacity={subSiteOpacity}
        textColor={
          siteData.projects !== undefined
            ? getTextColor(siteData.projects[activeProject], activeImage)
            : 'black'
        }
      />

      <ImgNav
        activeSubObj={
          siteData.projects ? siteData.projects[activeProject] : { images: [] }
        }
        activeImage={activeImage}
        onClick={(i) => setActiveImage(i)}
        subSiteOpacity={subSiteOpacity}
        textColor={
          siteData.projects !== undefined
            ? getTextColor(siteData.projects[activeProject], activeImage)
            : 'black'
        }
      />
    </div>
  );
}

export default App;
