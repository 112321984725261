import React from 'react';
import './Projects.css';

const Projects = ({
  projects = [],
  mobileOpen = false,
  onClick,
  activeProject,
  textColor
}) => (
  <div
    className={'Projects-wrapper' + (mobileOpen ? ' Projects-grid-open' : '')}
  >
    <div className="Projects-grid m-left m-right m-bottom screen-small-no-m">
      <p
        className="p-right-large m-bottom-small Projects-header Projects-text"
        style={{ color: textColor, borderColor: textColor }}
      >
        Name
      </p>
      <p
        className="Projects-header m-bottom-small Projects-text"
        style={{ color: textColor, borderColor: textColor }}
      >
        Last modified
      </p>
      {projects.map((item, index) => [
        <p
          className={
            'Projects-text Projects-text-id Projects-text-mobile' +
            (activeProject === index ? ' Projects-active' : '')
          }
          key={item.title}
          onClick={() => onClick(index)}
          style={{ color: textColor }}
        >
          {item.title}
        </p>,
        <p
          className="Projects-text Projects-text-mobile"
          key={item.title + '-' + item.modified}
          style={{ color: textColor }}
        >
          {item.modified}
        </p>,
      ])}
    </div>
  </div>
);

export default Projects;
