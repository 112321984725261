function getTextColor(activeProject, activeImage) {
  if (!activeProject) {
    return 'black';
  }

  if (activeProject.images && activeProject.images[activeImage]) {
    return activeProject.images[activeImage][1];
  } else {
    return 'black';
  }
}

export default getTextColor;
