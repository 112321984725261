import React from 'react';

const SiteTitle = ({ title = {}, textColor }) => (
  <h1 className="flex-column screen-small-block" style={{color: textColor}}>
    <span className="hide screen-small-inline">{title.A}</span>
    <span>{title.B}</span>
    <span>{title.C}</span>
  </h1>
);

export default SiteTitle;
