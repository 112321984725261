function getTitleString(activeProject, activeImage) {
  if (!activeProject) {
    return 'Title missing';
  }
  if (activeProject.images && activeProject.images[activeImage]) {
    return activeProject.images[activeImage][2];
  } else if (activeProject.type === 'about') {
    return '';
  } else {
    return activeProject.titleString;
  }
}

export default getTitleString;
