import React from 'react';
import './SubAbout.css';

const SubAbout = ({ activeSubObj = {}, textColor }) =>
  activeSubObj.paragraphs.map((item) => (
    <p key={item} className="SubAbout-text p-left p-right screen-small-no-p" style={{color: textColor}}>
      {item}
    </p>
  ));

export default SubAbout;
