import React, { Fragment } from 'react';
import './SubVideo.css';

const SubVideo = ({ activeSubObj = {}, textColor }) => [
  <div className="SubVideo-text" key="filler-div" />,
  <div className="SubVideo-video-top" key="video-div">
    <div className="SubVideo-wrapper">
      <iframe
        title={activeSubObj.title}
        width="560"
        height="315"
        src={activeSubObj.url}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        id="video"
      ></iframe>
    </div>
  </div>,
  <div className="SubVideo-text" key="text-holder">
    <p className="m-bottom screen-small-no-m">
      <bdi>{activeSubObj.description}</bdi>
    </p>
    {activeSubObj.links &&
      activeSubObj.links.map((link, index, array) => (
        <Fragment key={index}>
          <p>
            <bdi>{link.desc}</bdi>
          </p>
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            {`[${link.displayUrl}]`}
          </a>
        </Fragment>
      ))}
  </div>,
];

export default SubVideo;
