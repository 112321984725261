import React from 'react';
import './SubImages.css';

const SubImages = ({ images, activeImage, title }) =>
  images.map((image, index) => (
    <div
      className={
        'SubImages-wrapper' +
        (index === activeImage ? ' SubImages-wrapper-active' : '')
      }
      key={title + '-img-' + index}
    >
      <img
        className="SubImages-img"
        src={process.env.PUBLIC_URL + '/img/' + image[0]}
        alt="temp"
      />
    </div>
  ));

export default SubImages;
