import React from 'react';
import './TextRenderer.css';

const TextRenderer = ({ text = '', opacity = 0, textColor, links }) => (
  <>
    <p
      className={
        'TextRenderer-text p-left p-right p-bottom screen-small-no-p' +
        (text === '' ? ' hide' : '')
      }
      style={{ opacity: opacity, color: textColor }}
    >
      {text}
    </p>
    {links &&
      links.map((link, index, array) => (
        <div
          className="flex-column screen-small-flex-row m-bottom screen-small-no-m"
          key={index}
        >
          <p
            className="TextRenderer-text p-left p-right screen-small-no-p"
            style={{ opacity: opacity }}
          >
            {link.desc}
          </p>
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="TextRenderer-text p-left p-right screen-small-no-p screen-small-m-left-small"
            style={{ opacity: opacity }}
          >
            {`[${link.displayUrl}]`}
          </a>
        </div>
      ))}
  </>
);

export default TextRenderer;
